import type * as React from 'react';

import type { Xinglet } from '@xing-com/crate-xinglet';
// eslint-disable-next-line monorepo/forbidden-imports
import { TopBar } from '@xing-com/top-bar';

export type LayoutTopBarXingletProps = unknown & {
  title: string;
};

export default class LayoutTopBarXinglet implements Xinglet {
  public getComponent(): React.VFC<LayoutTopBarXingletProps> {
    return (props): JSX.Element => <TopBar {...props} />;
  }
}

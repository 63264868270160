import type * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { useExperiment } from '@xing-com/hub';
import { IconSearch, IconAI } from '@xing-com/icons';
import {
  mediaTopBarHidden,
  zIndexElevatedLayer5,
} from '@xing-com/layout-tokens';
import { SearchDropdownItem } from '@xing-com/platform-search-dropdown';
import { PopOver, usePopOver } from '@xing-com/pop-over';
import ttt from '@xing-com/ticktricktrack';
import { spaceS, spaceM, spaceL } from '@xing-com/tokens';

import { ActionSlot } from '../action-slot';

export type SearchDropdownProps = {
  icon?: typeof IconSearch;
  size?: React.ComponentProps<typeof TextButton>['size'];
  type?: string;
  showLabel?: boolean;
  title?: string;
};

const listMinWidth = '252px';
const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

const SearchPopOver = styled(PopOver)`
  display: none;
  min-width: ${listMinWidth};
  right: 0;
  z-index: ${zIndexElevatedLayer5};
  && {
    z-index: ${zIndexElevatedLayer5};
    padding: ${spaceM} ${spaceL};
  }
`;

const SearchDropdownContainer = styled.div`
  position: relative;
  &:hover ${SearchPopOver} {
    display: block;
  }
`;

export const SearchButton = styled(TextButton)<{ $type?: string }>`
  margin-left: ${spaceS};
  @media ${mediaTopBarHidden} {
    display: ${({ $type }) =>
      $type === 'subheaderSearchResults' ? 'inline' : 'none'};
  }
`;

export const SearchDropdown: React.VFC<SearchDropdownProps> = ({
  icon,
  showLabel,
}) => {
  const { formatMessage } = useIntl();
  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';
  const isAskXingExperiment = useExperiment('ABACUS-474') === 'B';

  const SEARCH_URL = isAiSearchForced
    ? '/jobs/search/ki/start'
    : '/jobs/search';

  const popOver = usePopOver();
  const toggleSearch = (): void => {
    popOver.togglePopOver();

    ttt.event('PropTrackAction', {
      PropTrackAction: 'navigation_search_drop_down',
      PropActionOrigin: 'navigation_badge_no_badge',
    });
  };

  return (
    <SearchDropdownContainer>
      <ActionSlot
        size="small"
        showLabel={showLabel}
        icon={icon}
        data-testid="search-dropdown-button"
        aria-label={formatMessage({
          id: 'FRAME_HEADER_SEARCH',
        })}
        text={formatMessage({
          id: 'FRAME_HEADER_SEARCH',
        })}
        onClick={toggleSearch}
      />
      <SearchPopOver
        data-testid="search-dropdown-menu"
        isInFlow
        onOutsideClick={popOver.handleHide}
        show={true}
        triggerRef={popOver.triggerRef}
      >
        <>
          <SearchDropdownItem
            itemKey="members"
            icon={IconSearch}
            href="/search/members"
            title={formatMessage({ id: 'SEARCH_DROPDOWN_MEMBERS' })}
            tracking={{
              sc_o: 'navigation_search_member_search',
              sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
            }}
          />
          <SearchDropdownItem
            itemKey="jobs"
            icon={IconSearch}
            href={SEARCH_URL}
            title={formatMessage({ id: 'SEARCH_DROPDOWN_JOBS' })}
            tracking={{
              sc_o: 'navigation_search_job_search',
              sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
            }}
          />
          <SearchDropdownItem
            itemKey="companies"
            icon={IconSearch}
            href="/search/companies"
            title={formatMessage({ id: 'SEARCH_DROPDOWN_COMPANIES' })}
            tracking={{
              sc_o: 'navigation_search_companies_search',
              sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
            }}
          />
          {isAskXingExperiment && (
            <SearchDropdownItem
              itemKey="ask-xing"
              icon={IconAI}
              href="/ask-xing"
              title={formatMessage({ id: 'ASK_XING_TITLE' })}
              tracking={{
                sc_o: 'navigation_search_ask_xing',
                sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
              }}
              //TODO future: review this tracking
            />
          )}
        </>
      </SearchPopOver>
    </SearchDropdownContainer>
  );
};
